import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./styles.module.css"

const TeamMember = ({ data }) => {
    return (
        <div className={`${styles.team_member} container-fluid`}>
            <div className="row">
                <div className="col-md-5">
                    <div className={styles.image_container}>
                        <GatsbyImage 
                        className={styles.gatsby_image} 
                        alt={data.headshot.alt ? data.headshot.alt : ""} 
                        image={data.headshot.data}
                        objectPosition={`${data.headshot.focalPoint.x*100}% ${data.headshot.focalPoint.y*100}%`} />
                    </div>
                </div>
                <div className={`${styles.team_member_data} col-md-7`}>
                    <div className={styles.text}>
                        <h2 className={`text-left ${styles.name}`}>{data.name}</h2>
                        <p className={styles.title}>{data.jobTitle}</p>
                        <p className={`${styles.background} ${styles.title}`}>Background
                            <a target="_blank" rel="noreferrer" href={data.linkedInUrl} className={`${styles.linked_in_url_mobile} ${styles.title}`}>LinkedIn</a>
                        </p>
                        <p className={styles.background_paragraph}>{data.background}</p>
                    </div>
                    <div className={styles.info}>
                        <a target="_blank" href={data.linkedInUrl} className={`${styles.linked_in_url} ${styles.title}`} rel="noreferrer">LinkedIn</a>
                        <div className={`${styles.locations} ${data.locations.length > 3 ? styles.more_than_3 : ""}`}>
                            {data.locations.map((location, index) =>
                                <p key={index} className={`${styles.title} ${styles.location}`}>{location.name}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamMember