import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import GenericBanner from "../components/genericBanner"
import About from "../components/about"
import TeamMember from "../components/teamMember"
import Cta from "../components/cta"

export default function MeetOurTeam({ data: { page }}) {
    return(
        <Layout>
          {page.banner.map((block, index) =>
              <GenericBanner key={index} banner={block} title={page.title} marginTop={60} marginBottom={60} mobileMarginBottom={10}/>
          )}
          <About headline={page.headline} description={page.description}/>
          {page.teamMembersSection.map((block, index) =>
              <TeamMember key={index} data={block}/>
          )}
          {page.cta.map((block, index) =>
            <Cta 
                key={index} 
                data={block} 
                type={`${block.model.apiKey === "shapes_cta" ? "shapes" : "default"}`}
            />
          )}
        </Layout>
    )
}

export const data = graphql`
query TeamQuery {
    page: datoCmsMeetOurTeam {
      title
      banner {
        backgroundImage {
          alt 
          data: gatsbyImageData
          url
        }
        mobileBackgroundImage {
          alt 
          data: gatsbyImageData
          url
        }
      }
      headline
      description
      teamMembersSection {
        headshot {
          data: gatsbyImageData (
            width: 672,
            height: 696,
            layout: FULL_WIDTH
          )
          focalPoint {
            x
            y
          }
          url
          alt
        }
        name
        jobTitle
        background
        linkedInUrl
        locations {
          name
        }
      }
      cta {
        ... on DatoCmsShapesCta {
            model {
            apiKey
            }
            headline
            body
            shapeType
            buttonText
            buttonUrl
            newTab
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
    return (
      <Seo 
        title={data.page.seo.title}
        description={data.page.seo.description}
        image={data.page.seo.image}
      />
    )
  }