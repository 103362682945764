import React from "react"
import * as styles from "./styles.module.css"

const About = ({ headline, description }) => {
    return (
        <div className={`${styles.container} container-fluid`}>
            <div className={`${styles.about} row`}>
                <div className={`col-lg-6 ${styles.col}`}>
                    <h2 className="text-left m-0">{headline}</h2>
                </div>
                <div className={`col-lg-6 ${styles.col}`}>
                    <p className="m-0">{description}</p>
                </div>
            </div>
        </div>
    )
}

export default About