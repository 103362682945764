// extracted by mini-css-extract-plugin
export var background = "styles-module--background--a62a7";
export var background_paragraph = "styles-module--background_paragraph--a6378";
export var blackish = "#121117";
export var colors = "\"../../assets/css/_colors.css\"";
export var deepOrange = "#FF8A0F";
export var deepYellow = "#FFCC23";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gatsby_image = "styles-module--gatsby_image--136ee";
export var gtWalsheim = "'gt-walsheim'";
export var image_container = "styles-module--image_container--9090c";
export var info = "styles-module--info--3d3c9";
export var lightBg = "#FFF8E8";
export var lightestPurple = "#E2DDFF";
export var linked_in_url = "styles-module--linked_in_url--6d30e";
export var linked_in_url_mobile = "styles-module--linked_in_url_mobile--0787e";
export var location = "styles-module--location--0985c";
export var locations = "styles-module--locations--5cb89";
export var mediumOrange = "#FFB96F";
export var more_than_3 = "styles-module--more_than_3--324b4";
export var name = "styles-module--name--7aaea";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var team_member = "styles-module--team_member--42232";
export var team_member_data = "styles-module--team_member_data--c79f9";
export var text = "styles-module--text--ed18d";
export var title = "styles-module--title--6ed6a";
export var yellowBtn = "#FFE07B";